var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import omit from 'omit.js';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined';
import { ConfigConsumer } from '../config-provider';
import Input from './Input';
const ActionMap = {
    click: 'onClick',
    hover: 'onMouseOver',
};
export default class Password extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            visible: false,
        };
        this.onVisibleChange = () => {
            const { disabled } = this.props;
            if (disabled) {
                return;
            }
            this.setState(({ visible }) => ({ visible: !visible }));
        };
        this.getIcon = (prefixCls) => {
            const { action } = this.props;
            const iconTrigger = ActionMap[action] || '';
            const icon = this.state.visible ? EyeOutlined : EyeInvisibleOutlined;
            const iconProps = {
                [iconTrigger]: this.onVisibleChange,
                className: `${prefixCls}-icon`,
                key: 'passwordIcon',
                onMouseDown: (e) => {
                    // Prevent focused state lost
                    // https://github.com/ant-design/ant-design/issues/15173
                    e.preventDefault();
                },
            };
            return React.createElement(icon, iconProps);
        };
        this.saveInput = (instance) => {
            if (instance && instance.input) {
                this.input = instance.input;
            }
        };
        this.renderPassword = ({ getPrefixCls }) => {
            const _a = this.props, { className, prefixCls: customizePrefixCls, inputPrefixCls: customizeInputPrefixCls, size, visibilityToggle } = _a, restProps = __rest(_a, ["className", "prefixCls", "inputPrefixCls", "size", "visibilityToggle"]);
            const inputPrefixCls = getPrefixCls('input', customizeInputPrefixCls);
            const prefixCls = getPrefixCls('input-password', customizePrefixCls);
            const suffixIcon = visibilityToggle && this.getIcon(prefixCls);
            const inputClassName = classNames(prefixCls, className, {
                [`${prefixCls}-${size}`]: !!size,
            });
            const props = Object.assign(Object.assign({}, omit(restProps, ['suffix'])), { type: this.state.visible ? 'text' : 'password', className: inputClassName, prefixCls: inputPrefixCls, suffix: suffixIcon, ref: this.saveInput });
            if (size) {
                props.size = size;
            }
            return <Input {...props}/>;
        };
    }
    focus() {
        this.input.focus();
    }
    blur() {
        this.input.blur();
    }
    select() {
        this.input.select();
    }
    render() {
        return <ConfigConsumer>{this.renderPassword}</ConfigConsumer>;
    }
}
Password.defaultProps = {
    action: 'click',
    visibilityToggle: true,
};
